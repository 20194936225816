$(function() {
    // Stats Counter
    var element_position = $('.rlp-hero__col-data').offset().top;
    var screen_height = $(window).height();
    var activation_offset = 0.5; //determines how far up the the page the element needs to be before triggering the function
    var activation_point = element_position - (screen_height * activation_offset);
    var max_scroll_height = $('body').height() - screen_height - 5;//-5 for a little bit of buffer
    var counterTriggered = false;

    //Does something when user scrolls to it OR
    //Does it when user has reached the bottom of the page and hasn't triggered the function yet
    $(window).on('scroll', function() {
        var y_scroll_pos = window.pageYOffset;

        var element_in_view = y_scroll_pos > activation_point;
        var has_reached_bottom_of_page = max_scroll_height <= y_scroll_pos && !element_in_view;

        if(!counterTriggered && (element_in_view || has_reached_bottom_of_page)) {
            $('.counter').countTo();
            counterTriggered = true;
        }
    });

    //Modal Slider
    var swiper = new Swiper(".rlp-os-swiper", {
        init: false,
        loop: true,
        spaceBetween: 1,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
    });

	swiper.on("beforeTransitionStart", function() {
		getSwiperPrevNext();
	}).on("slideChangeTransitionEnd", function() {
		// Send Slide to GTM
		var slideText = $('.swiper-slide-active .rlp-os-popup__content-copy h2').text();
		if (slideText){
			//console.log('grid view:'+slideText);
			pushGTM('view-profile',{
				'click_section' : 'body',
				'click_element' : 'profile',
				'click_text' : slideText
			});
		}
	});
   swiper.init();

	// Send Grid to GTM
	$('.rlp-os__col-person').click(function(){
		var gridText = $('h3',this).text();
		//console.log('grid open:'+gridText);
		pushGTM('click-profile',{
			'click_section' : 'body',
			'click_element' : 'profile',
			'click_text' : gridText
		});
	});

    function getSwiperPrevNext() {
        $(".rlp-os-popup .swiper-button-prev span").html($(".rlp-os-swiper .swiper-slide-prev").data("name"));
        $(".rlp-os-popup .swiper-button-next span").html($(".rlp-os-swiper .swiper-slide-next").data("name"));
    }

    //Modal
    var magnificPopupOptions = {
        type: 'inline',
		  autoHeight: true,
        showCloseBtn: false,
        fixedContentPos: true,
        loop: true,
        callbacks: {
            open: function () {
                $('html').css('overflow', 'hidden').css('margin-right', '0');
                swiper.update();
            }, close: function () {
                $('html').css('overflow', 'auto').css('margin-right', '0');
            }
        }
    }

    $('.rlp-os-modal')
        .magnificPopup(magnificPopupOptions)
        .click(function (e) {
            //Slide to correct slide on modal initial load
            swiper.slideTo($(e.target).closest(".rlp-os-modal").data("slide"), 0);
        });

    //Snow Fall
    $(document).snowfall({
        round: true,
        flakeCount: 75,
        minSize: 5,
        maxSize: 8,
        minSpeed: 1,
        maxSpeed: 3
    });

	//Video
	const iframe = document.querySelector('iframe');
	const player = new Vimeo.Player(iframe);
	player.on('play', function(){
		player.getVideoId().then(function(id){
			// GTM: dataLayer Push
			pushGTM('play-vimeo',{
				'action' : 'Play',
				'video_id' : id
			});
	  }).catch(function(error){
			// an error occurred
	  });
	});

});

// ===================================================================
// Global Function to event and properties object to GTM dataLayer
// ===================================================================
function pushGTM(eventName,eventProperties){
	if (typeof window.dataLayer !== 'undefined'){
		if (typeof eventName === 'string' && typeof eventProperties === 'object'){
			eventName = eventName.replace(' ','-').replace('_','-');
			window.dataLayer.push({
				'event': 'JS - '+eventName,
				'js-event': eventProperties
			});
		}
	}
}